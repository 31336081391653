html, body { height: 100%; }



		html {
			/* background: #000 url(img/bg_green_club.jpg) center center no-repeat; */
		    /*background: #000 url(img/01.jpeg) center center no-repeat;*/
    		background-size: cover;
    		/*backdrop-filter: grayscale(100%) brightness(60%) blur(10px);*/
		}
		body { 
			/*font-size: 15px;*/
			/*filter: invert(100%);*/
		    /*background: #222;*/
		    color: #fff;
		    /*padding: 20px;*/
		    /*background: url('img/bg_green_club.jpg') center center;*/
		    /*background-size: cover;*/
		    /* background: #000 url(img/bg_green_club.jpg) center center no-repeat; */
		    /*background: #000 url(img/01.jpeg) center center no-repeat;*/
    		background-size: cover;
    		/*backdrop-filter: grayscale(100%) brightness(60%) blur(10px);*/


		}
		h3 {
			margin: 0; padding: 0; font-size: 20px;
		}
		.item-row { border-top: 1px solid #333; padding: 10px 0;   }

		/*.cover { width: 90vh; border-radius: 10px; position: absolute; left: calc(50vw - 10vh); top: 5vh; }*/
		.cover { width: 90vh; border-radius: 10px; position: absolute; right: 10vh; top: 5vh; }
		/* .desc { font-size: 2.5vw; position: absolute; z-index: 1; left: 10vh; bottom: 10vh; width: 32vw; text-shadow: 0 0 20px black; } */
		.desc { font-size: 2.5vw; position: absolute; z-index: 1; left: 10vh; bottom: 23vh; width: calc(82vw - 90vh); 
            /* text-shadow: 0 0 20px black;    */
			border-left: 5px solid #177661; padding-left: 20px; 
			line-height: 1.3; 
		}

		.desc .author { font-weight: bold; font-size: 1.5vw; margin-top: 1.5vw; } 

			.open {
			    animation-name: open;
			    animation-duration: 1800ms;
			  }

		  @keyframes open {
		    0%   { transform: scale(.95,.95); opacity: 0; }
		    100%   { transform: scale(1,1); opacity: 1; }
		  }

		  .logo_sberai { position: absolute; bottom: 7vh; left: 10vh; width: 20vh; z-index: 1; }
		  .powerby { position: absolute; bottom: 7vh; left: 10vh; width: 20vh; z-index: 1; font-size: 1.2vw; line-height: 1.3; }
		  .logo_sbervox { position: absolute; top: 10vh; left: 10vh; width: 20vh; z-index: 1; }
		  .qr_code { position: absolute; bottom: 10vh; right: 5vh; width: 20vh; z-index: 1; 
            /* box-shadow: 0 0 20px black;  */
        }
		  .logodesc { position: absolute; top: 17vh; left: 10vh; color: #177661; z-index: 1; font-size: 1.2vw; }